<template>
  <div>
    <v-container class="mx-auto mx-lg-0">
      <div v-if="loading" class="d-flex align-center justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-row v-if="!loading && message" class="align-center mb-8">
        <v-col class="text-center">
          <v-card outlined max-width="960" elevation="12" class="mx-auto">
            <v-card-text>
              <h3 v-html="message"></h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="!loading && advisor">
        <div>
          <v-row>
            <v-col class="col-12 col-lg-8 pr-lg-10">
              <h3 class="mb-8">Account Details</h3>
              <v-form
                @submit.prevent="handleAccountUpdate"
                v-model="valid"
                class="mt-4"
              >
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.firstName"
                      :rules="firstNameRules"
                      label="First Name"
                      hint="REQUIRED"
                      persistent-hint
                      outlined
                      clearable
                      required
                      type="text"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.lastName"
                      :rules="lastNameRules"
                      label="Last Name"
                      hint="REQUIRED"
                      persistent-hint
                      outlined
                      clearable
                      required
                      type="text"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.email"
                      :rules="emailRules"
                      label="Email Address"
                      hint="REQUIRED"
                      persistent-hint
                      outlined
                      clearable
                      required
                      type="email"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.phone"
                      v-maska="'###-###-####'"
                      label="Phone Number"
                      hint="REQUIRED"
                      persistent-hint
                      outlined
                      clearable
                      type="tel"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.address.streetAddress1"
                      label="Street Address 1"
                      outlined
                      clearable
                      type="text"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.address.streetAddress2"
                      label="Street Address 2"
                      outlined
                      clearable
                      type="text"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-md-4">
                    <v-text-field
                      v-model="advisor.address.city"
                      label="City"
                      outlined
                      clearable
                      type="text"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                  <v-col class="col-12 col-md-4">
                    <v-select
                      v-model="advisor.address.state"
                      :items="states"
                      item-text="name"
                      item-value="abbreviation"
                      :menu-props="{ maxHeight: '400' }"
                      label="State"
                      clearable
                      outlined
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-select
                  ></v-col>
                  <v-col class="col-12 col-md-4">
                    <v-text-field
                      v-model="advisor.address.postalCode"
                      label="Postal Code"
                      :rules="zipCodeRules"
                      outlined
                      clearable
                      type="text"
                      class="rs-text-field--required"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.companyName"
                      label="Company Name"
                      outlined
                      clearable
                      type="text"
                      persistent-hint
                      hint="Company name, if applicable and different than parent organization"
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <v-text-field
                      v-model="advisor.companyUrl"
                      :rules="[handleCheckUrl]"
                      label="Company Website"
                      hint="Company URL, if applicable and different than parent organization"
                      persistent-hint
                      outlined
                      clearable
                      type="text"
                      class=""
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="advisor.credentials"
                      label="Advisor Credentials"
                      outlined
                      clearable
                      type="text"
                      placeholder="MBA"
                      persistent-hint
                      hint="Add any advisor credentials (e.g., MBA)"
                      background-color="white"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-textarea
                      v-model="advisor.disclosure"
                      name="disclosure"
                      outlined
                      label="Advisor Disclosure"
                      persistent-hint
                      hint="Enter any disclosures you want to appear on reports."
                      background-color="white"
                    ></v-textarea
                  ></v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      type="submit"
                      color="primary"
                      x-large
                      :disabled="!valid"
                      class="font-weight-bold"
                      >Update Account</v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>
            </v-col>
            <v-col>
              <h3 class="mb-8">Risk Score Subscription</h3>
              <v-card>
                <v-card-text>
                  <template
                    v-if="advisor.stripe && advisor.stripe.subscription"
                  >
                    <p>
                      Subscription Plan:<br />
                      <strong>{{
                        advisor.stripe.subscription.product_name
                          ? advisor.stripe.subscription.product_name
                          : "Not Specified"
                      }}</strong>
                    </p>
                    <p>
                      Subscription Status:<br />
                      <strong>{{
                        advisor.stripe.subscription.subscription_status
                          ? advisor.stripe.subscription.subscription_status.toUpperCase()
                          : "Not Specified"
                      }}</strong>
                    </p>
                    <p class="mb-0">
                      Next Billing:<br />
                      <strong>{{
                        advisor.stripe.subscription.next_billing_date
                          ? handelFormatDate(
                              advisor.stripe.subscription.next_billing_date
                            )
                          : "Not Specified"
                      }}</strong>
                    </p>
                  </template>

                  <p v-else>Subscription Not Found</p>
                </v-card-text>
              </v-card>

              <!-- <v-card outlined max-width="960" elevation="12" class="mx-auto">
                <v-card-text>
                  <h3 class="font-weight-bold accent--text text-uppercase">
                    <v-icon x-large class="mr-4" color="accent"
                      >mdi-professional-hexagon</v-icon
                    >Upgrade to Pro!
                  </h3>
                  <v-list disabled>
                    <v-list-item-group color="primary">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="accent">mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="accent--text"
                            >Custom Assessment Link</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="accent">mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="accent--text"
                            >Prospect Imports</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="accent">mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="accent--text"
                            >Unlimited Reports</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="accent">mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="accent--text"
                            >Prospect Activity Dashboard</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-divider class="mb-4"></v-divider>
                  <v-btn color="accent">Upgrade Plan</v-btn>
                </v-card-text>
              </v-card> -->
            </v-col></v-row
          >
        </div>

        <!-- <v-card maxWidth="800" elevation="12" class="mx-auto mx-xl-0">
          <v-card-title>Account Details</v-card-title>

          <v-card-text>
            
          </v-card-text>
        </v-card> -->
      </template>
    </v-container>
    <v-overlay :value="showOverlay" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
// import { API, graphqlOperation } from "aws-amplify";
// import { updateAdvisor } from "@/graphql/mutations";
import goTo from "vuetify/lib/services/goto";
import states from "@/assets/states.json";
import { validatePhone } from "@/services/validatePhoneNumber";
import { mapActions, mapGetters } from "vuex";

const initialAdvisor = function () {
  return {
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    address: {
      streetAddress1: null,
      streetAddress2: null,
      city: null,
      state: null,
      postalCode: null,
    },
    firstName: null,
    lastName: null,
    credentials: null,
    email: null,
    phone: null,
    avatar: null,
    prospectCriteria: null,
    showAdvisorCard: true,
    vanityNames: { items: [] },
    notificationEmails: [],
    disclosure: null,
    bypassCrm: null,
    scoreReportTemplateId: null,
    collectContactPostSurvey: null,
  };
};

export default {
  data() {
    return {
      advisor: null,
      states: states,
      loading: null,
      showOverlay: null,
      message: null,
      valid: false,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: null,
      // formData: null,
      // formData: {
      //   companyName: null,
      //   companyUrl: null,
      //   address: {
      //     streetAddress1: null,
      //     streetAddress2: null,
      //     city: null,
      //     state: null,
      //     postalCode: null,
      //   },
      //   firstName: null,
      //   lastName: null,
      //   credentials: null,
      //   email: null,
      //   phone: null,
      //   disclosure: null,
      //   acceptRegistrationTerms: true,
      //   password: null,
      //   confirmPassword: null,
      // },
      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) =>
          (v && v.length >= 2) || "First name must be at least two letters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => (v && v.length >= 2) || "Last name must be at least two letters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      zipCodeRules: [
        (v) =>
          !v ||
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid postal code",
      ],
      urlRules: [
        (v) =>
          !v ||
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test() ||
          // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          //   v)
          "Must be a valid URL and begin with http:// or https://",
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    formData() {
      return this.currentUser;
    },
  },
  formData: {
    handler(val) {
      this.advisor = {
        ...this.advisor,
        ...val,
      };
    },
    immediate: true,
    deep: true,
  },
  mounted() {
    this.advisor = new initialAdvisor();

    this.advisor = {
      ...this.advisor,
      ...this.formData,
    };
  },
  methods: {
    ...mapActions(["setCurrentUser"]),
    handelFormatDate(timestamp) {
      // Convert to a Date object.
      var date = new Date(timestamp);

      // Specify options for the output.
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "2-digit",
        // minute: "2-digit",
        // second: "2-digit",
        // timeZoneName: "short",
      };

      // Convert to a human-readable string in the current locale and timezone.
      var dateString = date.toLocaleDateString(undefined, options);

      return dateString;
    },
    handleCheckUrl(e) {
      let urlPattern = new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );

      let string = e;

      if (!string) {
        return true;
      }

      if (urlPattern.test(string)) {
        string = string.replace("https://", "").replace("http://", "");
        string = `https://${string}`;

        this.advisor.companyUrl = string;
        return true;
      } else {
        return "Please enter a valid url.";
      }
    },
    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);

        if (response.name === "Error") {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        console.log("validate phone error", errorMsg);

        this.message = `
              <p><strong>${errorMsg}</strong></p>
              <p class="error--text"><strong>${num}</strong></p>
              <p>Please enter a valid US phone number for the advisor.</p>`;
        this.showOverlay = false;
        return false;
      }
    },
    async handleAccountUpdate() {
      goTo(0);
      this.showOverlay = true;

      const advisorParams = Object.assign({}, this.advisor);
      if (advisorParams) {
        if (advisorParams.phone) {
          const isValid = await this.handleValidatePhone(advisorParams.phone);

          if (!isValid) {
            return;
          }
        }

        // const stringifyProspectCriteria = JSON.stringify(advisorParams.prospectCriteria);
        // advisorParams.prospectCriteria = stringifyProspectCriteria;

        //Advisor notification email addresses
        // if (this.useNotificationEmails && this.notificationEmails.length > 0) {
        //   const notificationEmailsToARaw = [];

        //   await Promise.all(
        //     this.notificationEmails.map((e) => {
        //       notificationEmailsToARaw.push(e.text);
        //     })
        //   );
        //   advisorParams.notificationEmails = notificationEmailsToARaw;
        //   console.log(
        //     "advisorParams.notificationEmails ",
        //     advisorParams.notificationEmails
        //   );
        // } else {
        //   advisorParams.notificationEmails = null;
        // }

        // Do not send Prospects object
        delete advisorParams.prospects;
        // Do not send Vanity Names object
        delete advisorParams.vanityNames;

        fetch(process.env.VUE_APP_SCORE_API + "advisor", {
          method: "POST",
          headers: {
            // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.advisor),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(
                "Something went wrong updating your account",
                response
              );
            }
          })
          .then((data) => {
            if (data.success) {
              console.log("update success", data);

              this.setCurrentUser(data.advisor);
              this.snackBarColor = "success";
              this.snackBarText = "Account Updated!";
              this.showSnackBar = true;

              this.showOverlay = false;
            } else {
              console.log("Error data:", data);
              throw new Error(data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.message = `<h3>Error, ${error}!</h3>>`;
            console.log("Error updating Advisor...", error.errors[0].message);
            this.message = `<p>There has been a problem attempting to update the advisor.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
            this.showOverlay = false;
          });

        // try {
        // const response = await API.graphql(
        //   graphqlOperation(updateAdvisor, { input: advisorParams })
        // );
        // const updatedAdvisor = response.data.updateAdvisor;
        // const parsedUpdatedAdvisor = this.parseCriteriaData(updatedAdvisor);

        // Object.assign(this.advisor, parsedUpdatedAdvisor);

        // if (this.vanityNamesToAdd.length > 0) {
        //   await this.handleUpdateAdvisorVanityNames(advisorParams.id);
        // }

        // } catch (error) {
        //   console.log("Error updating Advisor...", error.errors[0].message);
        //   this.message = `<p>There has been a problem attempting to update the advisor.</p>
        //   <p><strong>${error.errors[0].message}</strong></p>
        //   <p>Please refresh and try again.</p>`;
        // } finally {
        //   this.showOverlay = false;
        //   goTo(0);
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
